<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    
    import {
        createDepartment,
        createSubDepartment,
        createParentDepartment,

        getParentDepartmentByID,
        getSubDepartmentByID,
        getDepartmentByID,

        updateParentDepartmentByID,
        updateSubDepartmentByID,
        updateDepartmentByID,
    } from "../../helpers/apiHelper.svelte";
    
    import TextInput from "../../components/Form/TextInput.svelte";
    import Button from "../../components/Form/Button.svelte";
    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import { locationStore } from "../../store/location.store";
    import { notification } from "../../notification-handlers/notification.svelte";
    
    export let departmentID = -1;

    let departmentType = "normal";
    let breadcrumbText = "Departman Yönetimi"

    if (window.location.href.includes("/parent-department-management")) {
        breadcrumbText = "Ana Departman Yönetimi"
        departmentType = "parent";
    } else if (window.location.href.includes("/sub-department-management")) {
        breadcrumbText = "Alt Departman Yönetimi"
        departmentType = "sub";
    }
    
    let formDepartment = departmentID > -1 ? "Departman Bilgilerini Düzenle" : "Yeni Departman Oluştur";
    let saveButtonText = departmentID > -1 ? "Kaydet" : "Oluştur";
    let showLoading = false;
    let departmentDetails = null;

    function getDepartmentURLPrefix() {
        switch (departmentType) {
        case "parent":
            return "parent-department-management";
        case "sub":
            return "sub-department-management";
        default:
            return "department-management";
        }
    }

    function departmentCreate() {
        if (departmentType == "parent") {
            return createParentDepartment;
        } else if (departmentType == "sub") {
            return createSubDepartment;
        }
        return createDepartment;
    }

    function departmentGet() {
        if (departmentType == "parent") {
            return getParentDepartmentByID;
        } else if (departmentType == "sub") {
            return getSubDepartmentByID;
        }
        return getDepartmentByID;
    }

    function departmentUpdate() {
        if (departmentType == "parent") {
            return updateParentDepartmentByID;
        } else if (departmentType == "sub") {
            return updateSubDepartmentByID;
        }
        return updateDepartmentByID;
    }
    
    async function handleSubmit(e) {
        showLoading = true;
        
        const departmentFormData = Object.fromEntries(new FormData(e.target));
        let saveResp = null;
        
        if (departmentID > -1) {
            saveResp = await departmentUpdate()(
            departmentID,
            departmentFormData
            );
        } else {
            saveResp = await departmentCreate()(departmentFormData);
        }
        const saveResult = saveResp;
        
        showLoading = false;
        
        if (saveResp.status !== 200) {
            //TODO: Show error message on form footer
        }
        if (saveResult?.success) {
            // TODO: Push notification to screen
            window.scrollTo({ top: 0, behavior: "smooth" });
            navigate("/" + getDepartmentURLPrefix() + "/departments");
            return;
        } else if (saveResult?.message) {
            notification.toast({
                type: 'error',
                message: saveResult?.message
            });
        }
    }
    onMount(async () => {
        locationStore.set({breadcrumbText: breadcrumbText});
        showLoading = true;
        
        if (departmentID > -1) {
            const departmentDetailsResp = await departmentGet()(departmentID);
            if (!departmentDetailsResp?.success) {
                notification.toast({
                    type: 'error',
                    message: departmentDetailsResp?.message
                })
                return;
            }
            departmentDetails = departmentDetailsResp?.content;
        }
        showLoading = false;
    });
    
    function onCancelClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/" + getDepartmentURLPrefix() + "/departments");
    }
</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >
    <LoadingOverlay showOverlay={showLoading} />
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">
                {formDepartment}
            </h6>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 pt-0">
        <form
        id="new-department-form"
        on:submit|preventDefault={handleSubmit}
        >
        <div class="flex flex-wrap py-8">
            <div class="w-full px-4 mb-4">
                <TextInput
                fieldName="department_name"
                value={departmentDetails?.department_name}
                labelText="Departman Adı"
                />
            </div>
        </div>
    </form>
</div>
<div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
    <div class="flex-none">
        <Button
            text={saveButtonText}
            submitsForm="new-department-form"
            buttonType={departmentID > -1 ? "edit" : "save"}
        />
        <Button
            text="Vazgeç"
            buttonType="cancel"
            onClick={onCancelClick}
        />
    </div>
</div>
</div>
</div>
