<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    
    import {
        createTitle,
        getTitleByID,
        updateTitleByID,
    } from "../../helpers/apiHelper.svelte";
    
    import TextInput from "../../components/Form/TextInput.svelte";
    import Button from "../../components/Form/Button.svelte";
    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import { locationStore } from "../../store/location.store";
    import { notification } from "../../notification-handlers/notification.svelte";
    
    export let titleID = -1;
    
    let formTitle = titleID > -1 ? "Ünvan Bilgilerini Düzenle" : "Yeni Ünvan Oluştur";
    let saveButtonText = titleID > -1 ? "Kaydet" : "Oluştur";
    let showLoading = false;
    let titleDetails = null;
    
    async function handleSubmit(e) {
        showLoading = true;
        
        const titleFormData = Object.fromEntries(new FormData(e.target));
        let saveResp = null;
        
        if (titleID > -1) {
            saveResp = await updateTitleByID(
            titleID,
            titleFormData
            );
        } else {
            saveResp = await createTitle(titleFormData);
        }
        const saveResult = saveResp;
        
        showLoading = false;
        
        if (saveResp.status !== 200) {
            //TODO: Show error message on form footer
        }
        if (saveResult?.success) {
            // TODO: Push notification to screen
            window.scrollTo({ top: 0, behavior: "smooth" });
            navigate("/title-management/titles");
            return;
        } else if (saveResult?.message) {
            notification.toast({
                type: 'error',
                message: saveResult.message
            });
        }
    }
    onMount(async () => {
        locationStore.set({breadcrumbText: 'Ünvan Yönetimi'});
        showLoading = true;
        
        if (titleID > -1) {
            const titleDetailsResp = await getTitleByID(titleID);
            if (!titleDetailsResp?.success) {
                notification.toast({
                    type: 'error',
                    message: titleDetailsResp?.message
                });
                return;
            }
            titleDetails = titleDetailsResp?.content;
        }
        showLoading = false;
    });
    
    function onCancelClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/title-management/titles");
    }
</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >
    <LoadingOverlay showOverlay={showLoading} />
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">
                {formTitle}
            </h6>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 pt-0">
        <form
        id="new-title-form"
        on:submit|preventDefault={handleSubmit}
        >
        <div class="flex flex-wrap py-8">
            <div class="w-full px-4 mb-4">
                <TextInput
                fieldName="title_name"
                value={titleDetails?.title_name}
                labelText="Ünvan Adı"
                />
            </div>
        </div>
    </form>
</div>
<div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
    <div class="flex-none">
        <Button
            text={saveButtonText}
            submitsForm="new-title-form"
            buttonType={titleID > -1 ? "edit" : "save"}
        />
        <Button
            text="Vazgeç"
            buttonType="cancel"
            onClick={onCancelClick}
        />
    </div>
</div>
</div>
</div>
