<script>
  import { onMount, afterUpdate } from "svelte";
  export let reportDetails;
  
  function drawCallback() {
    let barData = reportDetails.options.map( (i1) => {
      return [i1.option_text.toString(), i1.answer_count, i1.is_correct ? '#479B5F' : '#b0bec5'];
    });
    let allData = [
      ["Seçenek", "Cevap Sayısı", {role: 'style'}],
      ...barData
    ]
    var data = google.visualization.arrayToDataTable(allData);
    var view = new google.visualization.DataView(data);
    view.setColumns([0, 1, {
      calc: "stringify",
      sourceColumn: 1,
      type: "string",
      role: "annotation"
    }, 2]);
    var xx = barData.reduce( (acc, current) => {
      if (acc.indexOf(current[1]) == -1) {
        acc.push(current[1]); 
      }
      return acc
    } , []).sort();
    var options = {
      title: reportDetails.q_name,
      width: '100%',
      height: 400,
      bar: {
        groupWidth: "50"
      },
      legend: { position: "none" },
      hAxis: {
        ticks: xx,
        title: 'Verilen Cevap Sayısı'
      },
      vAxis: {
        title: 'Seçenekler'
      }
    };
    var chart = new google.visualization.BarChart(document.getElementById(reportDetails.qid));
    chart.draw(view, options);
  }
  onMount(async () => {
    google.charts.load('current', {packages: ['corechart', 'bar']});
    google.charts.setOnLoadCallback(drawCallback);
  })
  
</script>
<div>
  <div id={reportDetails.qid}>
    Soru analiz grafiği yükleniyor...
  </div>
</div>