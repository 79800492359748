<script>
  import { navigate } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { getCompanies, deleteCompanyByID } from "../../helpers/apiHelper.svelte";
  import { locationStore } from '../../store/store.index';
  import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte'
  import ListViewContainer from '../../components/ListView/ListViewContainer.svelte';
  
  let companies = null;
  let isLoading = true;

  /* defaultChecked
  rowButtons
  createNewText
  onCreateNew */

  async function initializeCompanies() {
    isLoading = true;
    const resp = await getCompanies();
    isLoading = false;
    companies = resp?.content;
    if (companies && companies.length > 0) {
      companies = [...companies];
    } else {
      companies = [];
    }
  }
  
  function onCreateNew() {
    navigate('/company-management/companies/new');
  }

  function viewCompany(companyID) {
    navigate('/company-management/companies/' + companyID);
  }

  function editCompany(companyID) {
    navigate('/company-management/companies/' + companyID + '/edit');
  }

  async function deleteCompany(companyID) {
    await deleteCompanyByID(companyID);
    await initializeCompanies();
    /* setTimeout(async () => {
      
    }, 0); */
  }

  const rowButtons = [
    {
      name: "info",
      props: {
        leftIcon: "fas fa-info",
        buttonType :"info",
        onClick:(companyID) => viewCompany(companyID)
      }
    },
    {
      name: "edit",
      props: {
        leftIcon: "fas fa-edit",
        buttonType :"edit",
        onClick:(companyID) => editCompany(companyID)
      }
    },
    {
      name: "delete",
      props: {
        leftIcon: "fas fa-trash",
        buttonType :"delete",
        onClick:(companyID) => deleteCompany(companyID)
      }
    },
  ]

  
  onMount(async () => {
    locationStore.set({breadcrumbText: 'Şirket Yönetimi'});
    await initializeCompanies();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4 min-h-screen-w-header">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <LoadingOverlay showOverlay={isLoading}/>
    <ListViewContainer
      items={companies}
      createNewText="Yeni Şirket Oluştur"
      onCreateNew={onCreateNew}
      nameKey="company_name"
      idKey="id"
      rowButtons={rowButtons}
    />
  </div>
</div>
