<script>
    import { navigate } from 'svelte-routing';
    import InlineButton from '../../../components/Buttons/InlineButton.svelte';

    export let videoID = -1;
    export let title = "";
    export let description = "";
    export let thumbnailURL = "https://img.youtube.com/vi/QAVHikCB8ig/hqdefault.jpg";
    export let duration = 0;
    export let onVideoEditClick = () => null;
    export let onVideoDeleteClick = () => null;
</script>

<div class="flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg mt-4">
    <div class="flex flex-col justify-between flex-auto p-4">
        <div class="lg:flex md:grid md:grid-cols-2 w-full pr-4 max-w-full">
            <div class="lg:flex-none lg:flex-grow-0 lg:flex-shrink-0 flex justify-center items-center h-full">
                <img class="w-72" src="{thumbnailURL}" alt="{title}" />
            </div>
            <div class="lg:flex-1 lg:pl-4">
                <div class="flex justify-between items-center">
                    <span class="font-semibold text-xl text-blueGray-700">{title}</span>
                    <div>
                        <InlineButton onClick={() => onVideoEditClick(videoID)} buttonType="edit" text="Düzenle" textSize="text-sm"/>
                        <InlineButton onClick={() => onVideoDeleteClick(videoID)} buttonType="delete" text="Sil" textSize="text-sm"/>
                    </div>
                </div>
                <div class="text-base text-blueGray-700">
                    {@html description.replaceAll('\n','<br />')}
                </div>
            </div>
        </div>
        <p class="relative w-full max-w-full text-sm text-blueGray-400 mt-4">
            <span class="whitespace-nowrap text-base">
                <i class="fas fa-clock"/>
                {#if duration > 0}
                    <span class="ml-1">{parseInt(duration/60)} Dakika {parseInt(duration%60)} Saniye</span>
                {:else}
                    <span class="ml-1">Belirsiz</span>
                {/if}
            </span>
        </p>

    </div>
</div>
