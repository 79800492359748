<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  import TextInput from "../../../components/Form/TextInput.svelte";
  import ColorPicker from "../../../components/Form/ColorPicker.svelte";
  import Button from "../../../components/Form/Button.svelte";
  import CardDetailLine from "../../../components/Cards/CardDetailLine.svelte";
  import LoadingOverlay from "../../../components/Form/LoadingOverlay.svelte";
  import PredefinedValueSelect from "../../../components/PredefinedValueSelect/index.svelte";
  import { 
    getTrainingCategoryByID,
    systemGetPredefinedValues,
    updateTrainingCategoryByID,
    createTrainingCategory,
    deleteTrainingCategoryByID
  } from '../../../helpers/apiHelper.svelte'
  import InlineButton from '../../../components/Buttons/InlineButton.svelte';

  export let categoryID = -1;
  let showLoading = false;
  let categoryName = "";
  let categoryColorCode = "";
  let predefinedValues;
  let responsibleUnitList = [];
  const unitListTemplate = {
    city_id: 0,
    town_id: 0,
    company_id: 0,
    parent_department_id: 0,
    department_id: 0,
    sub_department_id: 0,
    position_id: 0,
    title_id: 0,
  };

  async function handleSubmit(e) {
    showLoading = true;
    var categoryFormData = Object.fromEntries(new FormData(e.target));
    if (!categoryFormData.categoryProperties) {
      categoryFormData.categoryProperties = {};
    }
    categoryFormData.assignees = responsibleUnitList;
    let saveResp = null;

    if (categoryID > -1) {
      saveResp = await updateTrainingCategoryByID(categoryID, categoryFormData);
    } else {
      saveResp = await createTrainingCategory(categoryFormData);
    }
    

		const saveResult = saveResp;
    if (saveResp.status !== 200) {
      //TODO: Show error message on form footer
    }

    showLoading = false;

    if (saveResult?.success) {
      // TODO: Push notification to screen
      onCancelClick();
    }
  }

  function onCancelClick() {
    navigate('/training-management/categories');
  }

  async function onDeleteClick(e) {
      showLoading = true;
      //TODO: Show prompt to the user
      const res = await deleteTrainingCategoryByID(categoryID);
      if (res.status != 200) {
        //TODO: Show error message on form footer
      }
      const result = res;
      if (!result?.success) {
        //TODO: Show error message on form footer
      }
      showLoading = false;
      navigate('/training-management/categories');
  }

  function removeResponsibleUnit(unitIndex) {
    responsibleUnitList.splice(unitIndex, 1);
    responsibleUnitList = [...responsibleUnitList];
  }

  function changeUnitProps(unitIndex, props) {
    responsibleUnitList[unitIndex] = props;
    responsibleUnitList = [...responsibleUnitList];
  }

  onMount( async () => {
      showLoading = true;
      if (categoryID > -1) {
        const response = await getTrainingCategoryByID(categoryID);
        if (!response.content) {
          //TODO: Show error about couldn't fetch category
        }
        categoryName = response.content.name;
        categoryColorCode = response.content.color_code;
        responsibleUnitList = response.content.assignees || [];
      }
      predefinedValues = (await systemGetPredefinedValues()).content;
      showLoading = false;
  });

</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
  >
    

    <LoadingOverlay showOverlay={showLoading}/>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          { categoryID > -1 ? `"${categoryName}" Kategorisini Düzenle` : 'Yeni Eğitim Kategorisi Oluştur'}
        </h6>
        {#if categoryID > -1}
          <Button text="Kategoriyi Sil" buttonType="delete" onClick={onDeleteClick} />
        {/if}
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10">
      {#if !showLoading}
      <form id="new-category-form" on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <TextInput
              fieldName="name"
              labelText="Kategori Adı"
              required
              value={categoryName}
            />
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <ColorPicker
              fieldName="color"
              labelText="Tema Rengi"
              required
              type="color"
              initializeRandomColor
              value={categoryColorCode}
            />
          </div>
        </div>
      </form>
      <CardDetailLine title="Sorumlu Olan Birimler" addButtonText="Yeni Tanımlama Ekle" showAddButton onAddClick={ () => responsibleUnitList = [...responsibleUnitList, unitListTemplate] } />
      {#each responsibleUnitList as unit, index }
        <div class="flex flex-wrap border-dashed border-b-2 border-l-2 border-r-2 border-gray-300 p-6" class:border-t-2={index === 0}>
          <div class="lg:w-1/12 flex justify-center items-center">
            <InlineButton text="Sil" buttonType="delete" onClick={() => removeResponsibleUnit(index)} />  
          </div>
          <div class="lg:w-11/12">
            <PredefinedValueSelect onChange={(values) => changeUnitProps(index, values)} options={predefinedValues} defaultValues={responsibleUnitList[index]} />
          </div>
        </div>
      {:else}
        <div class="flex flex-wrap border-dashed border-gray-300 p-6 justify-center items-center">
          <h3 class="text-gray-500 font-bold">Herhangi bir sorumlu birim tanımlaması yapılmadı</h3>
        </div>
      {/each}
      {/if}
    </div>
    <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
      <div class="flex-none">
        <Button text={categoryID > -1 ? 'Kaydet' : 'Oluştur'} submitsForm="new-category-form" buttonType={categoryID > -1 ? 'edit' : 'save'} />
        <Button text="Vazgeç" buttonType="cancel" onClick={onCancelClick} />
      </div>
    </div>
  </div>
</div>
