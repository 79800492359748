<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getUserActionReports() {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/event-tracker/user-action-report'));
        return apiResp;
    }

    export async function getUserActionReportsByUserID(userID) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/event-tracker/user-action-report'));
        return apiResp;
    }

    export async function getVideoActionReportsByUserID(userID) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/event-tracker/video-activity-report/' + userID));
        return apiResp;
    }

    export async function getTestAnswerReportsByTestID(testID) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/event-tracker/test-answer-report/' + testID));
        return apiResp;
    }

    export async function getHomePageReport() {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/event-tracker/home-page-report'));
        return apiResp;
    }
</script>