<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { locationStore } from "../../store/location.store";

    import {
        getCompanyByID,
    } from "../../helpers/apiHelper.svelte";

    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import CardDetailLine from "../../components/Cards/CardDetailLine.svelte";
    import { notification } from "../../notification-handlers/notification.svelte";

    export let companyID = -1;

    let showLoading = false;
    let companyDetails = null;

    onMount(async () => {
        locationStore.set({breadcrumbText: 'Şirket Yönetimi'});
        showLoading = true;
        if (companyID > -1) {
            const companyDetailsResp = await getCompanyByID(companyID);
            if (!companyDetailsResp?.success) {
                notification.toast({
                    type: 'error',
                    message: companyDetailsResp?.message
                });
                return;
            }
            companyDetails = companyDetailsResp?.content;
        }
        showLoading = false;
    });

    function onBackClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/company-management/companies");
    }
</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >
        <LoadingOverlay showOverlay={showLoading} />
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                    Şirket Detayları
                </h6>
            </div>
        </div>
        <div class="px-8 py-4">
            <CardDetailLine title="Şirket Adı" text={companyDetails?.company_name}/>
        </div>
    </div>
</div>
