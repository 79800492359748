<script>
  import HeaderStats from "../components/Headers/HeaderStats.svelte";
  import DataTable from "../components/DataTable/DataTable.svelte";
  import { onMount } from "svelte";
  import { getHomePageReport } from "../helpers/apiHelper.svelte";
    import { notification } from "../notification-handlers/notification.svelte";

  let demAmount = { total: 0, change: '-' };
  let demUsers = [];
  let mostWatchedVideos = [];
  let userCount = 0;
  let videoCount = 0;

  onMount(async () => {
    const response = await getHomePageReport();
    if (response.success) {
      const content = response?.content;
      demAmount = content?.dem_amount;
      demUsers = content?.dem_users;
      mostWatchedVideos = content?.most_watched_videos;
      userCount = content?.user_count;
      videoCount = content?.video_count;
    } else {
      notification.toast({
        type: 'error',
        message: response?.message
      });
    }
  })

  const activeUsersColumns = [
    { name: "Ad Soyad", keyName: "fullname" },
    { name: "Toplam DEM Para", keyName: "total_dem" },
  ];

  const mostWatchedVideosColumns = [
    { name: "Eğitim Adı", keyName: "name" },
    { name: "Eğitim Süresi (DK)", keyName: "title" },
    { name: "İzlenme Sayısı", keyName: "watch_count" },
  ]
</script>

<div class="min-h-screen-w-header">
  <HeaderStats
    demAmount={demAmount}
    userCount={userCount}
    videoCount={videoCount}
  />
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 xl:mb-0 px-4">
      <DataTable
        tableTitle="EN YÜKSEK DEM BAKİYESİ OLAN 10 KULLANICI"
        columns={activeUsersColumns}
        dataSource={demUsers}
      />
    </div>
    <div class="w-full mb-12 xl:mb-0 px-4">
      <DataTable
        tableTitle="EN ÇOK İZLENEN 10 EĞİTİM"
        columns={mostWatchedVideosColumns}
        dataSource={mostWatchedVideos}
      />
    </div>
  </div>
</div>
