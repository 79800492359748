<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  import { deleteAppUser } from "../../helpers/apiHelper.svelte";
  import { getAppUsers } from "../../helpers/apiHelper.svelte";

  import DataTable from "../../components/DataTable/DataTable.svelte";
  import Button from "../../components/Form/Button.svelte";
  import Select from "../../components/Form/Select.svelte";
  import { locationStore } from "../../store/store.index";
  import RowOperations from './RowOperations.svelte';

  let appUsers = [];
  let isLoading = true;
  const tableColumns = [
    { name: "Ad Soyad", keyName: "fullname" },
    { name: "Telefon Numarası", keyName: "phone" },
    { name: "Şirket", keyName: "work_information.company_name" },
    { name: "Ana Departman", keyName: "work_information.parent_department_name" },
    { name: "Departman", keyName: "work_information.department_name" },
    { name: "Alt Departman", keyName: "work_information.sub_department_name" },
    { name: "İl", keyName: "work_information.city_name" },
    { name: "İlçe", keyName: "work_information.town_name" },
    { name: "Pozisyon", keyName: "work_information.position_name" },
    { name: "Ünvan", keyName: "work_information.title_name" },
    { name: 'İşlemler', keyName: 'actions', type: 'actions' }
  ];

  async function initializeAppUsers() {
    const resp = await getAppUsers();
    appUsers = resp?.content;
  }

  function onRowEditClick(row) {
    navigate("/user-management/users/" + row.id + "/edit");
  }

  async function onRowDeleteClick(row) {
    isLoading = true;
    await deleteAppUser(row.id);
    await initializeAppUsers();
    isLoading = false;
  }
  
  const rowOperations = {
    component: RowOperations,
    props: { onRowEditClick, onRowDeleteClick }
  }

  onMount(async () => {
    locationStore.set({breadcrumbText: 'Kullanıcı Yönetimi'});
    await initializeAppUsers();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4">
  <div class="flex w-full flex-wrap hidden">
    <div class="w-full lg:w-3/12 px-4 mb-4">
      <Select fieldName="gender" labelText="Kullanıcı" selectOptions={[
              { text: "Utku Belemir", value: "1"},
              { text: "Arda Dogan", value: "2"}
            ]}/>
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
      <Select fieldName="gender" labelText="Kullanıcı" selectOptions={[
              { text: "Utku Belemir", value: "1"},
              { text: "Arda Dogan", value: "2"}
            ]}/>
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
      <Select fieldName="gender" labelText="Kullanıcı" selectOptions={[
              { text: "Utku Belemir", value: "1"},
              { text: "Arda Dogan", value: "2"}
            ]}/>
    </div>
    <div class="w-full flex justify-end items-center lg:w-3/12 mb-4 mt-6 px-4">
      <Button witdhClass="h-4" text="Filtre" buttonType="success"/>
    </div>
  </div>
  <div class="w-full mb-12 xl:mb-0 px-4">
    <DataTable
      showLoading={isLoading}
      tableTitle="KULLANICI LİSTESİ"
      headerButtons={[
        {
          text: "YENİ KULLANICI OLUŞTUR",
          color: "bg-green-500",
          hoverColor: "hover:bg-green-600",
          activeColor: "active:bg-green-700",
          nextRoute: "/user-management/users/new"
        }
      ]}
      columns={tableColumns}
      dataSource={appUsers}
      rowOperations={rowOperations}
      showPagination={true}
    />
  </div>
</div>
