<script>
import ListView from "./ListView.svelte";
import SortableListView from "./SortableListView.svelte";

export let sortable = false;
</script>
{#if sortable}
    <SortableListView {...$$restProps}/>
{:else}
    <ListView {...$$restProps}/>
{/if}