<!-- App.svelte -->
<script>
  import { onMount } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  
  import { userStore } from './store/store.index';
  import { getCookie } from "./helpers/utils.svelte";
  import { dashboardGetUser } from "./helpers/apiHelper.svelte";
  
  import Navbar from "./components/Navbars/Navbar.svelte";
  import Sidebar from "./components/Sidebar/Sidebar.svelte";
  import FooterAdmin from "./components/Footers/FooterAdmin.svelte";
  
  import Login from "./views/auth/Login.svelte";
  import ForgotPassword from "./views/auth/ForgotPassword.svelte";
  import DashboardMain from "./views/DashboardMain.svelte";
  import Settings from "./views/Settings.svelte";
  import UserList from "./views/user-management/UserList.svelte";
  import UserForm from "./views/user-management/UserForm.svelte";
  import CategoryList from "./views/training-management/category/CategoryList.svelte";
  import CategoryForm from "./views/training-management/category/CategoryForm.svelte";
  
  import VideoList from "./views/training-management/videos/VideoList.svelte";
  import VideoForm from "./views/training-management/videos/VideoForm.svelte";
  
  import DepartmentList from "./views/department-management/DepartmentList.svelte";
  import DepartmentForm from "./views/department-management/DepartmentForm.svelte";
  import DepartmentDetails from "./views/department-management/DepartmentDetails.svelte";
  
  import CompanyList from "./views/company-management/CompanyList.svelte";
  import CompanyForm from "./views/company-management/CompanyForm.svelte";
  import CompanyDetails from "./views/company-management/CompanyDetails.svelte";
  
  import TitleList from "./views/title-management/TitleList.svelte";
  import TitleForm from "./views/title-management/TitleForm.svelte";
  import TitleDetails from "./views/title-management/TitleDetails.svelte";
  
  import PositionList from "./views/position-management/PositionList.svelte";
  import PositionForm from "./views/position-management/PositionForm.svelte";
  import PositionDetails from "./views/position-management/PositionDetails.svelte";
  
  import UserActionsReports from "./views/reports/UserActionsReports.svelte";
  
  import TestList from "./views/test-management/TestList.svelte";
  import TestForm from "./views/test-management/TestForm.svelte";
  import TestRenderer from "./views/test-management/TestRenderer.svelte";
  
  import FillTest from "./views/fill-test/FillTest.svelte";
  import TestAnswerReports from "./views/reports/TestAnswerReports.svelte";
  import DEMList from "./views/dem-management/DEMList.svelte";
  import DEMUserBalanceHistory from "./views/dem-management/DEMUserBalanceHistory.svelte";
    import NotificationProvider from "./providers/NotificationProvider.svelte";
  
  
  export let url = "";
  
  let userData = null;
  let isLoading = true;
  userStore.subscribe(store => {
    userData = store;
  });
  
  onMount(async () => {
    let shouldLogin = window?.location?.pathname.indexOf('/auth') < 0
                      && window?.location?.pathname.indexOf('/fill-test') < 0
                      && window?.location?.pathname.indexOf('/sinav') < 0;
    if (getCookie("bluc")) {
      const userResponse = await dashboardGetUser();
      if (userResponse.success && userResponse?.content?.user) {
        userStore.set(userResponse.content.user);
        shouldLogin = false;
      }
    }
    isLoading = false;
    if (shouldLogin) {
      navigate('/login');
    }
  });
  
  function generateRoutePath(path, param = null) {
    let pathParam = [path, param].filter((i1) => i1).join('/');
    pathParam = pathParam.replaceAll('//','/');
    return pathParam;
  }
  
  const routeListAuth = [
  {
    path:'/',
    param: '*dashboard',
    subRoutes: [
    { path: '', component: DashboardMain },
    { path: 'settings', component: Settings }
    ],
  },
  {
    path:'user-management',
    param: '*userManagement',
    subRoutes: [
    { path: 'users', component: UserList },
    { path: 'users/new', component: UserForm },
    { path: 'users/:userID/edit', component: UserForm },
    ],
  },
  {
    path:'training-management',
    param: '*trainingManagement',
    subRoutes: [
    { path: 'categories', component: CategoryList },
    { path: 'categories/new', component: CategoryForm },
    { path: 'categories/:categoryID/edit', component: CategoryForm },
    { path: 'categories/:categoryID/videos', component: VideoList },
    { path: 'categories/:categoryID/videos/new', component: VideoForm },
    { path: 'categories/:categoryID/videos/:videoID/edit', component: VideoForm },
    ]
  },
  {
    path:'parent-department-management',
    param: '*departmentManagement',
    subRoutes: [
    { path: 'departments', component: DepartmentList },
    { path: 'departments/new', component: DepartmentForm },
    { path: 'departments/:departmentID/edit', component: DepartmentForm },
    { path: 'departments/:departmentID', component: DepartmentDetails },
    ]
  },
  {
    path:'department-management',
    param: '*departmentManagement',
    subRoutes: [
    { path: 'departments', component: DepartmentList },
    { path: 'departments/new', component: DepartmentForm },
    { path: 'departments/:departmentID/edit', component: DepartmentForm },
    { path: 'departments/:departmentID', component: DepartmentDetails },
    ]
  },
  {
    path:'sub-department-management',
    param: '*departmentManagement',
    subRoutes: [
    { path: 'departments', component: DepartmentList },
    { path: 'departments/new', component: DepartmentForm },
    { path: 'departments/:departmentID/edit', component: DepartmentForm },
    { path: 'departments/:departmentID', component: DepartmentDetails },
    ]
  },
  {
    path:'company-management',
    param: '*companyManagement',
    subRoutes: [
    { path: 'companies', component: CompanyList },
    { path: 'companies/new', component: CompanyForm },
    { path: 'companies/:companyID/edit', component: CompanyForm },
    { path: 'companies/:companyID', component: CompanyDetails },
    ]
  },
  {
    path:'position-management',
    param: '*positionManagement',
    subRoutes: [
    { path: 'positions', component: PositionList },
    { path: 'positions/new', component: PositionForm },
    { path: 'positions/:positionID/edit', component: PositionForm },
    { path: 'positions/:positionID', component: PositionDetails },
    ]
  },
  {
    path:'title-management',
    param: '*titleManagement',
    subRoutes: [
    { path: 'titles', component: TitleList },
    { path: 'titles/new', component: TitleForm },
    { path: 'titles/:titleID/edit', component: TitleForm },
    { path: 'titles/:titleID', component: TitleDetails },
    ]
  },
  {
    path:'user-actions-reports',
    param: '*reports',
    subRoutes: [
      { path: '', component: UserActionsReports },
    ]
  },
  {
    path:'video-activity-reports',
    param: '*reports',
    subRoutes: [
      { path: '', component: UserActionsReports },
    ]
  },
  {
    path:'test-answers-reports',
    param: '*reports',
    subRoutes: [
      { path: '', component: TestAnswerReports },
    ]
  },
  {
    path:'test-management',
    param: '*testManagement',
    subRoutes: [
    { path: 'tests', component: TestList },
    { path: 'tests/new', component: TestForm },
    { path: 'tests/:testID/edit', component: TestForm },
    { path: 'tests/:testID/view-test', component: TestRenderer },
    ]
  },
  {
    path:'dem-management',
    param: '*demManagement',
    subRoutes: [
    { path: 'balances', component: DEMList },
    { path: 'balances/user/:userID', component: DEMUserBalanceHistory },
    /* { path: 'tests/new', component: TestForm },
    { path: 'tests/:testID/edit', component: TestForm },
    { path: 'tests/:testID/view-test', component: TestRenderer }, */
    ]
  },
  ];
  
  const routeListNormal = [
  { path: 'login', component: Login },
  { path: 'forgot-password', component: ForgotPassword }
  ];
  
</script>
<NotificationProvider />
<Router url="{url}">
  {#if isLoading}
    <span>Loading</span>
  {:else}
    {#if userData && userData.username }
      {#each routeListAuth as authRoute}
        {#if authRoute?.subRoutes?.length > 0}
          <Route path={generateRoutePath(authRoute.path, authRoute?.param)}>
            <div>
              <Sidebar location={location}/>
              <div class="relative md:ml-64 bg-blueGray-100 min-h-screen flex flex-col justify-between">
                <Navbar/>
                <div class="px-4 md:px-10 mx-auto w-full mt-0 flex-grow">
                  <Router url={generateRoutePath(authRoute.path)}>
                    {#each authRoute.subRoutes as subRoute}
                      <Route path={generateRoutePath(subRoute.path, subRoute.param)} component={subRoute.component} />
                    {/each}
                  </Router>
                </div>
                <FooterAdmin />
              </div>
            </div>
          </Route>
        {:else}
          <Route path={generateRoutePath(authRoute.path, authRoute?.param)} component={authRoute.component} />
        {/if}
      {/each}
    {:else}
      {#each routeListNormal as normalRoute }
        <Route path={generateRoutePath(normalRoute.path, normalRoute?.param)} component={normalRoute.component} />
      {/each}
    {/if}
    <Route path={generateRoutePath('fill-test/:complexID/:testHash')} component={FillTest} />
    <Route path={generateRoutePath('sinav/:urlID')} component={FillTest} />
  {/if}
</Router>