<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { locationStore } from "../../store/location.store";

    import {
        getPositionByID,
    } from "../../helpers/apiHelper.svelte";

    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import CardDetailLine from "../../components/Cards/CardDetailLine.svelte";

    export let positionID = -1;

    let showLoading = false;
    let positionDetails = null;

    onMount(async () => {
        locationStore.set({breadcrumbText: 'Pozisyon Yönetimi'});
        showLoading = true;
        if (positionID > -1) {
            const positionDetailsResp = await getPositionByID(positionID);
            if (!positionDetailsResp?.success) {
                errorMessage = positionDetailsResp.message;
                return;
            }
            positionDetails = positionDetailsResp?.content;
        }
        showLoading = false;
    });

    function onBackClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/position-management/positions");
    }
</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >
        <LoadingOverlay showOverlay={showLoading} />
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                    Pozisyon Detayları
                </h6>
            </div>
        </div>
        <div class="px-8 py-4">
            <CardDetailLine title="Pozisyon Adı" text={positionDetails?.position_name}/>
            <CardDetailLine title="Öncelik" text={positionDetails?.priority}/>
        </div>
    </div>
</div>
