<script>
    import InlineButton from "../Buttons/InlineButton.svelte";
    export let title = "";
    export let text = "";
    export let showAddButton = false;
    export let addButtonText = "";
    export let onAddClick;
</script>
<div class="py-4">
    <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        {title}
        {#if showAddButton}
        <span class="float-right">
            <InlineButton text={addButtonText} buttonType="success" onClick={onAddClick} />  
        </span>
        {/if}
    </span>
    <p>
        {text}
    </p>
</div>