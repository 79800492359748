<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store'
  import { navigate } from 'svelte-routing';
  import { userStore } from '../../store/store.index';

  import { getUserBalanceHistory, createDEMEarning } from "../../helpers/apiHelper.svelte";

  import DataTable from "../../components/DataTable/DataTable.svelte";
  import { locationStore } from "../../store/store.index";
  import BalanceRowOperations from './BalanceRowOperations.svelte';
  import Modal from '../../components/Modal/Modal.svelte';
  import TextInput from '../../components/Form/TextInput.svelte';
  import Textarea from '../../components/Form/Textarea.svelte';
  import Button from '../../components/Form/Button.svelte';
    import { notification } from '../../notification-handlers/notification.svelte';

  export let userID = -1;
  let balanceHistory = [];
  let totalBalance = 0;
  let isLoading = true;
  let isNewRecordModalOpen = false;

  const tableColumns = [
    { name: "Tip", keyName: "action_meaning" },
    { name: "Kaynak", keyName: "resource_name" },
    { name: "Miktar", keyName: "amount" },
    { name: "Ek Açıklamalar", keyName: "additional_info" },
    { name: "Tarih", keyName: "earned_at" },
    { name: 'İşlemler', keyName: 'actions', type: 'actions' }
  ];

  async function intializeBalanceHistory() {
    const resp = await getUserBalanceHistory(userID);
    balanceHistory = resp?.content?.balance_history;
    totalBalance = resp?.content?.total_balance;
  }

  function onRowInfoClick(row) {
    navigate("/dem-management/user-balances/" + row.id);
  }
  
  const balanceRowOperations = {
    component: BalanceRowOperations,
    props: { onRowInfoClick }
  }

  const toggleNewRecordModal = () => {
    isNewRecordModalOpen = !isNewRecordModalOpen;
  }

  const handleCreateNewRecord = async (e) => {
    toggleNewRecordModal();
    isLoading = true;
    const formValues = Object.fromEntries(new FormData(e.target));
    let saveResp = null;
    saveResp = await createDEMEarning(userID, 'manuel', formValues.amount, formValues.additional_info, get(userStore)?.id);

    if (saveResp.status !== 200) {
      notification.toast({
        type: 'error',
        message: "DEM Para kaydı oluşturulamadı"
      });
    }
    if (saveResp?.success) {
      intializeBalanceHistory();
    } else if (saveResp?.message) {
      notification.toast({
        type: 'error',
        message: saveResp?.message,
      })
    }
    isLoading = false;
  }

  onMount(async () => {
    console.log('OK', get(userStore));
    locationStore.set({breadcrumbText: 'DEM Para Yönetimi'});
    await intializeBalanceHistory();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <DataTable
      showLoading={isLoading}
      tableTitle="BAKİYE HAREKETLERİ"
      columns={tableColumns}
      dataSource={balanceHistory}
      rowOperations={balanceRowOperations}
      headerButtons={[
        {
          text: "YENİ KAYIT OLUŞTUR",
          color: "bg-green-500",
          hoverColor: "hover:bg-green-600",
          activeColor: "active:bg-green-700",
          onClick: toggleNewRecordModal
        }
      ]}
      showPagination={false}
    />
  </div>
</div>
<Modal visible={isNewRecordModalOpen} onClose={toggleNewRecordModal} title="Yeni Kayıt Oluştur">
  <form id="new-record-form" on:submit|preventDefault={handleCreateNewRecord}>
    <div class="flex flex-wrap">
      <div class="w-full px-4 mb-4">
        <TextInput fieldName="amount" labelText="Miktar" type="number" required />
      </div>
      <div class="w-full px-4 mb-4">
        <Textarea fieldName="additional_info" labelText="Açıklama"/>
      </div>
    </div>
  </form>
  <div class="flex justify-end flex-auto px-4 py-6 ">
    <div class="flex-none">
      <Button text="Kaydet" submitsForm="new-record-form" buttonType="save" />
      <Button text="Vazgeç" buttonType="cancel" onClick={toggleNewRecordModal} />
    </div>
  </div>
</Modal>