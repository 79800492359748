<script>
    import { onMount } from 'svelte';
    import { navigate } from "svelte-routing";

    import { getTrainingCategories } from '../../../helpers/apiHelper.svelte';
    import LoadingOverlay from '../../../components/Form/LoadingOverlay.svelte';
    import CategoryCard from "../../../components/Cards/CategoryCard.svelte";

    let showLoading = true;

    function onCreateClick() {
        navigate("/training-management/categories/new");
    }

    let trainingCategories = [];
    onMount(async () => {
        const resp = await getTrainingCategories();
        trainingCategories = resp?.content;
        showLoading = false;
    });


</script>
{#if showLoading}
    <LoadingOverlay showOverlay={showLoading} />
{:else}
    <div class="container m-auto h-full min-h-screen-w-header py-4">
        <LoadingOverlay showOverlay={showLoading} />
        <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-8 py-4">
            <div class="relative flex flex-col min-w-0 min-h-44 rounded mb-6 xl:mb-0 h-full
                    bg-green-500 hover:bg-green-600 active:bg-green-700
                    shadow-lg hover:shadow-xl ease-linear transition-all duration-150
                    text-center text-lg font-bold text-white justify-center align-middle
                    cursor-pointer select-none"
                on:click={onCreateClick}
            >
                <i class="fas fa-plus text-3xl"></i>
                Yeni Kategori Oluştur
            </div>
            {#each trainingCategories as category}
                <CategoryCard category={category} />
            {/each}
        </div>
    </div>
{/if}