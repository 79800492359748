<script>
    import { onMount } from 'svelte';
    import { Tabs, TabList, TabPanel, Tab } from '../../components/Tabs/index.svelte';
  
    import { 
      getTestAnswerReportsByTestID,
        getTests
    } from "../../helpers/apiHelper.svelte";
  
    import DataTable from "../../components/DataTable/DataTable.svelte";
    import { locationStore } from "../../store/store.index";
    import Select from '../../components/Form/Select.svelte';
    import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte';
    import VideoProgressCard from '../training-management/videos/VideoProgressCard.svelte';
    import AutoComplete from '../../components/AutoComplete/AutoComplete.svelte';
    import ChartRenderer from './ChartRenderer.svelte';
    import AnswersDataTable from './AnswersDataTable.svelte';
  
    let isLoading = true;
    let testsToSelect = [];
    let reportDetails = null;
    let selectedTestID = null;
    let aa = {};

    async function selectTest(testID) {
      isLoading = true;
      selectedTestID = testID
      let reportResponse = await getTestAnswerReportsByTestID(selectedTestID);
      reportDetails = reportResponse?.content || null;
      isLoading = false;
    }
  
    onMount(async () => {
      locationStore.set({breadcrumbText: 'Sınav Sonuç Raporları'});
      const testsResp = await getTests(false);
      testsToSelect = testsResp?.content?.map( (test) => {
        test.combinedLabel = test.title + ' - ' + test.url_id
        return test;
      });
      isLoading = false;
    });
  </script>
  
<div class="flex flex-wrap mt-4">
  <LoadingOverlay showOverlay={isLoading} />
    <div class="w-full mb-12 xl:mb-0 px-4">
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="w-full mt-2">
          <AutoComplete labelField="combinedLabel" valueField="id" placeholder="Lütfen sınav seçiniz" selectOptions={testsToSelect} onChange={selectTest}/>
        </div>
        <div id="chart_div"></div>
        <div class="w-full py-6">
          {#if selectedTestID === null}
            <div class="text-xl text-center font-semibold">
              Rapor görüntülemek için lütfen sınav seçiniz
            </div>
          {:else if isLoading}
            <div class="text-xl text-center font-semibold">
              Yükleniyor
            </div>
          {:else}
            <Tabs>
              <TabList>
                <Tab>Kümülatif Değerlendirme</Tab>
                <Tab>Kişi Bazlı Değerlendirme</Tab>
              </TabList>

              <TabPanel>
                {#each reportDetails?.report as answerReports }
                  <ChartRenderer reportDetails={answerReports}/>
                {/each}
              </TabPanel>

              <TabPanel>
                <AnswersDataTable {...reportDetails.table_report}/>
              </TabPanel>
            </Tabs>
          {/if}
      </div>
    </div>
</div>
</div>