<script>
import { bottom } from "@popperjs/core";

import { elementInViewPort } from "../../helpers/utils.svelte";


    export let labelText = "";
    export let selectOptions = [];
    export let labelField = "";
    export let valueField = "";
    export let placeholder;
    export let onChange;
    let inputRef;
    let inputValue = "";
    let index = null;
    let filteredSelectOptions = [];
    let inputID = Math.random().toString(36).slice(2, 12);

    const onInput = ({ target: { value } }) => {
        if (!value.length) {
            filteredSelectOptions = selectOptions;
            return;
        }
        filteredSelectOptions = selectOptions.filter(selectOption => selectOption[labelField].toLowerCase().includes(value.toLowerCase()));
    }

    const navigateList = (e) => {
        if (e.key === "ArrowDown" && index <= filteredSelectOptions.length-1) {
            index === null || index + 1 === filteredSelectOptions.length ? index = 0 : index += 1;
        } else if (e.key === "ArrowUp" && index !== null) {
            index === 0 ? index = filteredSelectOptions.length-1 : index -= 1
        } else if (e.key === "Enter") {
            setInputVal(filteredSelectOptions[index][valueField]);
        } else {
            return;
        }
    }

    const setInputVal = (option) => {
        inputValue = option[labelField];
        if (typeof onChange === 'function') {
            onChange(option[valueField]);
        }
        inputRef.focus();
    }

    const clearFilterList = () => {
        setTimeout( () => filteredSelectOptions = [], 250);
    }

</script>
<svelte:window on:keydown={navigateList} />
<div>
    <div class="relative inline-block w-full">
        {#if labelText}
        <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            for={inputID}
        >
            {labelText}
        </label>
        {/if}
        <input
            class="border-0 px-3 py-3 placeholder-gray-400 text-blueGray-600 bg-white rounded shadow hover:shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={placeholder}
            type="text"
            id={inputID}
            value={inputValue}
            bind:this={inputRef}
            on:blur={clearFilterList}
            on:click={onInput}
            on:input={onInput}
        />
        {#if filteredSelectOptions.length > 0}
		<ul class="absolute max-h-96 m-0 p-0 border border-solid border-gray-400 w-full overflow-y-auto shadow-2xl rounded z-50">
			{#each filteredSelectOptions as filteredSelectOption, i}
                <li
                id={i}
                class="list-none p-3 border-gray-400 z-100 top-full left-0 right-0 cursor-pointer bg-white hover:bg-blue-500 hover:text-white active:bg-blue active:text-white"
                class:autocomplete-active={i === index}
                class:bg-gray-100={i%2 === 0}
                on:click={() => setInputVal(filteredSelectOption)}>
                    {filteredSelectOption[labelField]}
                </li>
			{/each}			
		</ul>
	{/if}
    </div>
</div>