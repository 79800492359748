<script>
    // library for creating dropdown menu appear on click
    import { createPopper } from "@popperjs/core";
    import { removeCookie } from '../../helpers/utils.svelte';
  
    let dropdownPopoverShow = false;
  
    let btnDropdownRef;
    let popoverDropdownRef;
    export let user;

    const outsideListener = (event) => {
        if (!btnDropdownRef.contains(event.target) && !popoverDropdownRef.contains(event.target)) {
            dropdownPopoverShow = true;
            toggleDropdown(event);
        }
    }
  
    const toggleDropdown = (event) => {
      event.preventDefault();
      if (dropdownPopoverShow) {
        dropdownPopoverShow = false;
        window.removeEventListener('click', outsideListener);
      } else {
        dropdownPopoverShow = true;
        window.addEventListener('click', outsideListener);
        createPopper(btnDropdownRef, popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    };

    const onLogoutClick = (event) => {
        removeCookie('bluc');
        window.location.reload();
    }
  
  </script>
  
  <div>
    <a
      class="text-blueGray-500 block py-1 px-3 hover:bg-opacity-10 hover:bg-white ease-linear transition-all duration-150"
      href=""
      bind:this="{btnDropdownRef}"
      on:click="{toggleDropdown}"
    >
      <div class="flex flex-wrap justify-center items-center">
        <p class="text-white">{user?.fullname}</p>
        <div class="px-4">
          <img src="https://lifesycle-cdn.s3.eu-west-2.amazonaws.com/avatars/unknown.jpg" alt="..." class="shadow w-10 rounded-full align-middle border-none" />
        </div>
      </div>
    </a>
    <div
      bind:this="{popoverDropdownRef}"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block':'hidden'}"
    >
      <span
        on:click={onLogoutClick}
        class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:bg-gray-200 text-blueGray-700 ease-linear transition-all duration-150"
      >
        Çıkış Yap
      </span>
    </div>
  </div>
  