<script>
    import { onMount } from 'svelte';
    import { locationStore } from "../../store/store.index";
  
    import { getVideoActionReportsByUserID, getAppUsers } from "../../helpers/apiHelper.svelte";

    import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte';
    import VideoProgressCard from '../training-management/videos/VideoProgressCard.svelte';
    import AutoComplete from '../../components/AutoComplete/AutoComplete.svelte';
  
    let isLoading = true;
    let usersToSelect = [];
    let reportDetails = null;
    let selectedUserID = null;

    const tableColumns = [
      { name: "Ad Soyad", keyName: "fullname" },
      { name: "Aksiyon Tipi", keyName: "event_desc" },
      { name: "Tarih", keyName: "action_date" },
    ];

    async function selectUser(userID) {
      isLoading = true;
      selectedUserID = userID
      let reportResponse = await getVideoActionReportsByUserID(selectedUserID);
      reportDetails = reportResponse?.content || null;
      isLoading = false;
    }
  
    async function initializeActionReports() {
    }

    onMount(async () => {
      locationStore.set({breadcrumbText: 'Kullanıcı Eğitim İzleme Raporları'});
      const usersResp = await getAppUsers(true);
      usersToSelect = usersResp.content;
      isLoading = false;
    });
  </script>
  
<div class="flex flex-wrap mt-4">
  <LoadingOverlay showOverlay={isLoading} />
    <div class="w-full mb-12 xl:mb-0 px-4">
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="w-full mt-2">
          <AutoComplete labelField="fullname" valueField="id" placeholder="Lütfen kullanıcı seçiniz" selectOptions={usersToSelect} onChange={selectUser}/>
        </div>
        <div class="w-full py-6">
          {#if selectedUserID === null}
            <div class="text-xl text-center font-semibold">
              Video izleme detaylarını görüntülemek için kullanıcı seçiniz
            </div>
          {:else if isLoading}
            <div class="text-xl text-center font-semibold">
              Yükleniyor
            </div>
          {:else if !reportDetails || reportDetails?.categoriesWithVideos?.length === 0}
            <div class="text-xl text-center font-semibold">
              Kullanıcıya atanmış herhangi bir içerik bulunamadı
            </div>
          {:else}
            {#each reportDetails.categoriesWithVideos as categoryDetail, index}
              <div class={"lg:flex justify-between items-center grid grid-cols-1 gap-8 border-b-2 py-8 mb-8" + (index !== 0 ? " mt-16" : "")}>
                <span class="flex items-center font-semibold flex-1 text-xl">
                    <div class="flex justify-center items-center px-4 flex-0">
                        <div style={`background-color: #${categoryDetail?.color_code}`} class="text-white p-3 text-center inline-flex items-center justify-center w-6 h-6 shadow-lg rounded-full">
                        </div>
                    </div>
                    {categoryDetail?.name}
                </span>
              </div>
              {#each categoryDetail?.videos as video}
                <VideoProgressCard
                  title={video.title}
                  thumbnailURL={video.thumbnail_url}
                  progressNumber={parseInt(reportDetails?.videoProgresses?.[video.id]?.progress || null)}
                />
              {:else}
                <div class="text-xl text-center font-semibold">
                  Kategoriye ait video bulunmamaktadır
                </div>
              {/each}
            {/each}
          {/if}
      </div>
    </div>
</div>
</div>