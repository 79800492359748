<script>
  // core components
  import StatsCard from "../Cards/StatsCard.svelte";

  export let userCount = '-';
  export let videoCount = '-';
  export let demAmount = { total: '-', change: '-' };
</script>

<!-- Header -->
<div class="relative py-16 pb-16">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <StatsCard
            statSubtitle="TOPLAM KULLANICI SAYISI"
            statTitle={userCount}
            hideStat={true}
            statIconName="fas fa-users"
            statIconColor="bg-pink-500"
          />
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <StatsCard
            statSubtitle="TOPLAM VIDEO SAYISI"
            statTitle={videoCount}
            statIconName="far fa-chart-bar"
            statIconColor="bg-red-500"
            hideStat={true}
          />
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <StatsCard
            statSubtitle="TOPLAM DEM PARA"
            statTitle={demAmount.total}
            hideStat={demAmount.change === '-'}
            statArrow='up'
            statPercent={demAmount.change}
            statPercentColor="text-emerald-500"
            statDescripiron="Geçen haftadan beri"
            statIconName="fas fa-percent"
            statIconColor="bg-emerald-500"
          />
        </div>  
      </div>
    </div>
  </div>
</div>
