<script>
    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';
    import VideoCard from './VideoCard.svelte';
    import Button from '../../../components/Form/Button.svelte';
    import { deleteTrainingVideo, getTrainingCategoryWithVideos } from '../../../helpers/apiHelper.svelte'

    export let categoryID = -1;

    if (categoryID < 0) {
        navigate('/404');
    }

    let categoryName = "";
    let categoryColorCode = "";
    let videos = [];

    async function fillCategoryData() {
        const response = await getTrainingCategoryWithVideos(categoryID);
        if (!response.content) {
        //TODO: Show error about couldn't fetch category
        }
        categoryName = response.content.name;
        categoryColorCode = response.content.color_code;
        if (response.content.videos?.length > 0) {
            videos = [...response.content.videos];
        } else {
            videos = [];
        }
    }

    onMount( async () => {
        await fillCategoryData();
    });

    function onNewVideoClick() {
        navigate('/training-management/categories/' + categoryID + '/videos/new');
    }

    function onVideoEditClick(videoID) {
        navigate('/training-management/categories/' + categoryID + '/videos/' + videoID + '/edit');
    }

    async function onVideoDeleteClick(videoID) {
        //TODO: Show delete prompt
        await deleteTrainingVideo(categoryID, videoID);
        await fillCategoryData();
    }

</script>

<div class="container m-auto h-full min-h-screen-w-header py-4">
    <div class="lg:flex justify-between items-center grid grid-cols-1 gap-8 border-b-2 py-8 mb-8">
        <span class="flex items-center font-semibold flex-1 text-2xl">
            <div class="flex justify-center items-center px-4 flex-0">
                <div style="background-color: {categoryColorCode}" class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full">
                </div>
            </div>
            {categoryName}
        </span>
        <div class="flex-none">
            <Button text="Yeni Video Ekle" witdhClass="w-full" onClick={onNewVideoClick} />
        </div>
    </div>
    {#if videos.length === 0}
        <div class="text-3xl text-center font-bold">
            Hiçbir video eklenmemiş
        </div>
    {/if}
    {#each videos as video}
        <VideoCard
            videoID={video.id}
            title={video.title}
            description={video.description}
            thumbnailURL={video.thumbnail_url}
            duration={video.duration}
            onVideoEditClick={onVideoEditClick}
            onVideoDeleteClick={onVideoDeleteClick}
        />
    {/each}
</div>