<script>
    export let labelText = "";
    export let required = false;
    export let value = "";
    export let type = "text";
    export let fieldName = "";
    export let initializeRandomColor = false;
    let inputID = Math.random().toString(36).slice(2, 12);

    function setType(element) {
        element.type = type;
    }

    if (initializeRandomColor && !value) {
        value = '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase()
    }

</script>
<label
    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
    for={inputID}
>
    {labelText}
    {#if required}
        <span class="text-red-500">*</span>
    {/if}
</label>
<input
    class="bg-white rounded h-13 focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
    name={fieldName}
    id={inputID}
    {required}
    bind:value
    use:setType
/>