<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { locationStore } from "../../store/location.store";

    import {
        getDepartmentByID,
        getParentDepartmentByID,
        getSubDepartmentByID
    } from "../../helpers/apiHelper.svelte";

    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import CardDetailLine from "../../components/Cards/CardDetailLine.svelte";
    import { notification } from "../../notification-handlers/notification.svelte";

    export let departmentID = -1;
    let departmentType = "normal";
    let breadcrumbText = "Departman Yönetimi"

    if (window.location.href.includes("/parent-department-management")) {
        breadcrumbText = "Ana Departman Yönetimi"
        departmentType = "parent";
    } else if (window.location.href.includes("/sub-department-management")) {
        breadcrumbText = "Alt Departman Yönetimi"
        departmentType = "sub";
    }

    function getDepartmentURLPrefix() {
        switch (departmentType) {
        case "parent":
            return "parent-departments";
        case "sub":
            return "sub-departments";
        default:
            return "departments";
        }
    }

    function departmentGet() {
        if (departmentType == "parent") {
            return getParentDepartmentByID;
        } else if (departmentType == "sub") {
            return getSubDepartmentByID;
        }
        return getDepartmentByID;
    }

    let showLoading = false;
    let departmentDetails = null;

    onMount(async () => {
        locationStore.set({breadcrumbText: breadcrumbText});
        showLoading = true;
        if (departmentID > -1) {
            const departmentDetailsResp = await departmentGet()(departmentID);
            if (!departmentDetailsResp?.success) {
                notification.toast({
                    type: 'error',
                    message: departmentDetailsResp?.message
                })
                return;
            }
            departmentDetails = departmentDetailsResp?.content;
        }
        showLoading = false;
    });

    function onBackClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/" + getDepartmentURLPrefix() + "/departments");
    }
</script>

<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >
        <LoadingOverlay showOverlay={showLoading} />
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                    Departman Detayları
                </h6>
            </div>
        </div>
        <div class="px-8 py-4">
            <CardDetailLine title="Departman Adı" text={departmentDetails?.department_name}/>
        </div>
    </div>
</div>
