<script>
import { onMount } from "svelte";
import { navigate } from "svelte-routing";
import Button from "../../../components/Form/Button.svelte";

import LoadingOverlay from "../../../components/Form/LoadingOverlay.svelte";
import Textarea from "../../../components/Form/Textarea.svelte";
import TextInput from "../../../components/Form/TextInput.svelte";
import {
  getTrainingYoutubeVideo,
  updateTrainingVideo,
  createTrainingVideo
} from "../../../helpers/apiHelper.svelte";

export let categoryID = -1;
export let videoID = -1;

let showLoading = false;
let videoTitle = "";
let youtubeID = "";
let videoDescription = "";

async function handleSubmit(e) {
  showLoading = true;
  const videoData = Object.fromEntries(new FormData(e.target));
  videoData.categoryID = categoryID;

  let saveResp = null;

  if (videoID > -1) {
    saveResp = await updateTrainingVideo(videoID, videoData);
  } else {
    saveResp = await createTrainingVideo(videoData);
  }

  const saveResult = saveResp;
  if (saveResp.status !== 200) {
    //TODO: Show error message on form footer
  }
  if (saveResult?.success) {
    // TODO: Push notification to screen
    navigate("/training-management/categories/" + categoryID + "/videos");
  }
  showLoading = false;
}

function onCancelClick() {
  navigate("/training-management/categories/" + categoryID + "/videos");
}

function updateYoutubeThumbnail(yt_id) {
  youtubeID = yt_id
}
if (videoID > -1) {
  onMount( async () => {
    showLoading = true;
    const response = await getTrainingYoutubeVideo(categoryID, videoID);
    if (!response.content) {
    //TODO: Show error about couldn't fetch category
    }
    videoTitle = response.content.title;
    youtubeID = response.content.youtube_id;
    videoDescription = response.content.description;
    categoryID = response.content.category_id;
    showLoading = false;
});
}

</script>

<div class="container mx-auto mt-4 py-4 min-h-screen-w-header">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
  >
    <LoadingOverlay showOverlay={showLoading} />
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {videoID > -1 ? `"${videoTitle}" Eğitim Videosunu Düzenle` : 'Yeni Eğitim Videosu Oluştur'}
        </h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10">
      <form id="new-video-form" on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <TextInput fieldName="title" value={videoTitle} labelText="Video Başlığı" required />
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4 grid lg:grid-cols-2 grid-cols-1 gap-8">
            <div>
              <TextInput
                  fieldName="youtube_id"
                  labelText="Youtube Video ID"
                  value={youtubeID}
                  onChange={updateYoutubeThumbnail}
                  required
              />
            </div>
            <div>
              <span
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Ekran Görüntüsü
              </span>
              <img class="w-full" src="{`https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`}" alt="x" />
            </div>
          </div>
          <div class="w-full px-4 mb-4">
            <Textarea
              fieldName="description"
              labelText="Video Açıklaması"
              value={videoDescription}
              required
            />
          </div>
        </div>
      </form>
    </div>
    <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
      <Button text={videoID > -1 ? 'Kaydet' : 'Oluştur'} submitsForm="new-video-form" buttonType={videoID > -1 ? 'edit' : 'save'} />
      <Button text="Vazgeç" buttonType="cancel" onClick={onCancelClick} />
    </div>
  </div>
</div>
