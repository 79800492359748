<script>
import { onMount } from "svelte";

  import { link } from "svelte-routing";

  // core components
  import ProfileDropdown from "../../views/header-bar/ProfileDropdown.svelte";

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  export let location;
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold px-0"
      href="/"
    >
      <img src="/assets/img/blueonline_logo.png" class="mx-auto max-h-12" />
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <ProfileDropdown />
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              BLUE ONLINE
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Heading -->

      <hr class="my-4 md:min-w-full" />
      
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a
            use:link
            href="/"
            class="text-xs uppercase py-3 font-bold block {(location.origin + '/') === location.href ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}">
              <i class="fas fa-tv mr-2 text-sm {(location.origin + '/') === location.href ? 'opacity-75' : 'text-blueGray-300'}"></i>
                Ana Sayfa
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/user-management/users"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/user-management/users') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-users mr-2 text-sm {location.href.indexOf('/user-management/users') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Kullanıcı Yönetimi
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/test-management/tests"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/test-management/tests') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-question mr-2 text-sm {location.href.indexOf('/test-management/tests') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
              Sınav Yönetimi
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/dem-management/balances"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/dem-management') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
          <img src="/assets/img/dem_logo.png" class="inline-block" style="max-width: 1rem;margin-right: 0.3rem">
              DEM Para Yönetimi
          </a>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Eğitim İşlemleri
      </h6>
      <ul>
        <li class="items-center">
          <a
            use:link
            href="/training-management/categories"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/training-management/categories') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-graduation-cap mr-2 text-sm {location.href.indexOf('/training-management/categories') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
              Eğitim Yönetimi
          </a>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        RAPORLAMA
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
        <li class="items-center">
          <a
            use:link
            href="/user-actions-reports"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/user-actions-reports') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-chart-line mr-2 text-sm {location.href.indexOf('/user-actions-reports') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Kullanıcı Raporları
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/test-answers-reports"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/test-answers-reports') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-chart-line mr-2 text-sm {location.href.indexOf('/test-answers-reports') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Sınav Raporları
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/video-activity-reports"
            style="user-select: none; pointer-events: none;color: #AAA"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/video-activity-reports') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-chart-line mr-2 text-sm {location.href.indexOf('/video-activity-reports') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            İçerik Raporları
          </a>
        </li>
        <!-- <li class="items-center">
          <a
            use:link
            class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            href="/auth/login"
          >
            <i class="fas fa-chart-line text-blueGray-300 mr-2 text-sm"></i>
            DEM Para Raporları - Güncelleniyor
          </a>
        </li> -->
      </ul>

      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        TANIMLAMALAR
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
        <li class="items-center">
          <a
            use:link
            href="/title-management/titles"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/title-management/titles') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/title-management/titles') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Ünvan Yönetimi
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/position-management/positions"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/position-management/positions') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/position-management/positions') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Pozisyon Yönetimi
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/parent-department-management/departments"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/parent-department-management/departments') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/parent-department-management/departments') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Ana Departman Yönetimi
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/department-management/departments"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/department-management/departments') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/department-management/departments') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Departman Yönetimi
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/sub-department-management/departments"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/sub-department-management/departments') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/sub-department-management/departments') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Alt Departman Yönetimi
          </a>
        </li>



        <li class="items-center">
          <a
            use:link
            href="/company-management/companies"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/company-management/companies') !== -1 ? 'text-bo-logo-orange hover:text-bo-logo-orange':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tasks mr-2 text-sm {location.href.indexOf('/company-management/companies') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Şirket Yönetimi
          </a>
        </li>
      </ul>

    </div>
  </div>
</nav>
