<script>
  import { navigate } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { getPositions, deletePositionByID, updatePositionPriorities } from "../../helpers/apiHelper.svelte";
  import { locationStore } from '../../store/store.index';
  import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte'
  import ListViewContainer from '../../components/ListView/ListViewContainer.svelte';
  
  let positions = null;
  let isLoading = true;
  let sortedPositions = null;

  async function initializePositions() {
    isLoading = true;
    const resp = await getPositions();
    isLoading = false;
    positions = resp?.content;
    if (positions && positions.length > 0) {
      positions = [...positions];
    }
  }
  
  function onCreateNew() {
    navigate('/position-management/positions/new');
  }

  function viewPosition(positionID) {
    navigate('/position-management/positions/' + positionID);
  }

  function editPosition(positionID) {
    navigate('/position-management/positions/' + positionID + '/edit');
  }

  async function deletePosition(positionID) {
    await deletePositionByID(positionID);
    await initializePositions();
  }

  async function onPriorityChange(newItems) {
    newItems.forEach( (item, index) => {
      item.priority = index;
    });
    sortedPositions = newItems;
    isLoading = true;
    await updatePositionPriorities(sortedPositions);
    await initializePositions();
    isLoading = false;
  }

  const rowButtons = [
    {
      name: "info",
      props: {
        leftIcon: "fas fa-info",
        buttonType :"info",
        onClick:(positionID) => viewPosition(positionID)
      }
    },
    {
      name: "edit",
      props: {
        leftIcon: "fas fa-edit",
        buttonType :"edit",
        onClick:(positionID) => editPosition(positionID)
      }
    },
    {
      name: "delete",
      props: {
        leftIcon: "fas fa-trash",
        buttonType :"delete",
        onClick:(positionID) => deletePosition(positionID)
      }
    },
  ]

  
  onMount(async () => {
    locationStore.set({breadcrumbText: 'Pozisyon Yönetimi'});
    await initializePositions();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4 min-h-screen-w-header">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <LoadingOverlay showOverlay={isLoading}/>
    <ListViewContainer
      items={positions}
      sortable={true}
      onSorted={onPriorityChange}
      createNewText="Yeni Pozisyon Oluştur"
      onCreateNew={onCreateNew}
      nameKey="position_name"
      idKey="id"
      rowButtons={rowButtons}
    />
  </div>
</div>
