<script>
    export let title = "";
    export let thumbnailURL = "https://img.youtube.com/vi/QAVHikCB8ig/hqdefault.jpg";
    export let progressNumber = 0;
    
    function calculateProgressPercentage(progressNumber) {
        /* if (progressNumber >= 1 && progressNumber < 3) {
            return 0;
        } else if (progressNumber >= 3 && progressNumber < 7) {
            return 25;
        } else if (progressNumber >= 7 && progressNumber < 15) {
            return 50;
        } else if (progressNumber >= 15 && progressNumber < 31) {
            return 75;
        } else if (progressNumber == 31) {
            return 100;
        } */
        progressNumber = parseInt(progressNumber, 10);
        if (progressNumber === 3) {
            return 20;
        } else if (progressNumber === 7) {
            return 40;
        } else if (progressNumber === 15) {
            return 60;
        } else if (progressNumber === 31) {
            return 80;
        } else if (progressNumber === 63) {
            return 100;
        } else {
            return 0;
        }
    }

    let progressStyle = `opacity: 0.4`;
    let progressText = "Görüntülenmedi"
    let percentage = null;
    if (!Number.isNaN(progressNumber)) {
        percentage = calculateProgressPercentage(progressNumber);
        progressText = `%${percentage} izlendi`;
        if (percentage == 0) {
            progressStyle = "";
        } else if (percentage < 100) {
            progressStyle = `background: linear-gradient(to right, rgba(0, 255, 0, 0.2) ${percentage - 10}%, white ${percentage}%)`;
        } else {
            progressStyle = `background-color: rgba(0, 255, 0, 0.2)`;
            progressText = "Eğitim Tamamlandı"
        }
    }
</script>

<div class="flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg mt-4" style={progressStyle}>
    <div class="flex flex-col justify-between flex-auto p-4">
        <div class="lg:flex md:grid md:grid-cols-2 w-full pr-4 max-w-full items-center">
            <div class="lg:flex-none lg:flex-grow-0 lg:flex-shrink-0 flex justify-center items-center h-full">
                <img class="w-12" src="{thumbnailURL}" alt="{title}" />
            </div>
            <div class="lg:flex-1 lg:pl-4">
                <div class="flex justify-between items-center">
                    <span class="font-semibold text-l text-blueGray-700">{title}</span>
                    <p class="relative">
                        <span class="whitespace-nowrap text-sm">
                            <i class={`fas fa-${percentage === 100 ? "check" : 'clock'}`}/>
                            <span class="ml-1">{progressText}</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>
