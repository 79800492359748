<script>
    import { navigate } from 'svelte-routing';
    import { onMount } from 'svelte';
    import { getTitles, deleteTitleByID } from "../../helpers/apiHelper.svelte";
    import { locationStore } from '../../store/store.index';
    import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte'
    import ListViewContainer from '../../components/ListView/ListViewContainer.svelte';
    
    let titles = null;
    let isLoading = true;

    async function initializeTitles() {
      isLoading = true;
      const resp = await getTitles();
      isLoading = false;
      titles = resp?.content;
      if (titles && titles.length > 0) {
        titles = [...titles];
      }
    }
    
    function onCreateNew() {
      navigate('/title-management/titles/new');
    }

    function viewTitle(titleID) {
      navigate('/title-management/titles/' + titleID);
    }

    function editTitle(titleID) {
      navigate('/title-management/titles/' + titleID + '/edit');
    }

    async function deleteTitle(titleID) {
      await deleteTitleByID(titleID);
      await initializeTitles();
    }

    const rowButtons = [
      {
        name: "info",
        props: {
          leftIcon: "fas fa-info",
          buttonType :"info",
          onClick:(titleID) => viewTitle(titleID)
        }
      },
      {
        name: "edit",
        props: {
          leftIcon: "fas fa-edit",
          buttonType :"edit",
          onClick:(titleID) => editTitle(titleID)
        }
      },
      {
        name: "delete",
        props: {
          leftIcon: "fas fa-trash",
          buttonType :"delete",
          onClick:(titleID) => deleteTitle(titleID)
        }
      },
    ]

    
    onMount(async () => {
      locationStore.set({breadcrumbText: 'Ünvan Yönetimi'});
      await initializeTitles();
      isLoading = false;
    });
  </script>
  <div class="flex flex-wrap mt-4 min-h-screen-w-header">
    <div class="w-full mb-12 xl:mb-0 px-4">
      <LoadingOverlay showOverlay={isLoading}/>
      <ListViewContainer
        items={titles}
        createNewText="Yeni Ünvan Oluştur"
        onCreateNew={onCreateNew}
        nameKey="title_name"
        idKey="id"
        rowButtons={rowButtons}
      />
    </div>
  </div>
  