<script>
    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';
    import { customAlphabet } from 'nanoid'

    import TextInput from "../../components/Form/TextInput.svelte";
    import Button from "../../components/Form/Button.svelte";
    import CardDetailLine from "../../components/Cards/CardDetailLine.svelte";
    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import {
        getTestByID,
        updateTestByID,
        deleteTestByID,
        createTest,
        appDomain,
        systemGetPredefinedValues,
        setTestAssignees,
        getTestAssignees,
    } from '../../helpers/apiHelper.svelte'
    import InlineButton from '../../components/Buttons/InlineButton.svelte';
    import Textarea from '../../components/Form/Textarea.svelte';
    import Modal from '../../components/Modal/Modal.svelte';
    import PredefinedValueSelect from '../../components/PredefinedValueSelect/index.svelte';
    import { text } from 'svelte/internal';

    export let testID = -1;
    let showLoading = false;
    let testTitle = "";
    let testDescription = "";
    let errorMessage = "";
    let questions = [];
    let url_id;
    let timer_minutes = 15;
    let timer_seconds = 0;
    let average_point = 50;
    let predefinedValues = null;

    let selectionModalOpen = false;
    let responsibleUnitList = [];
    const unitListTemplate = {
      city_id: 0,
      town_id: 0,
      company_id: 0,
      parent_department_id: 0,
      department_id: 0,
      sub_department_id: 0,
      position_id: 0,
      title_id: 0,
    };

    function removeResponsibleUnit(unitIndex) {
      responsibleUnitList.splice(unitIndex, 1);
      responsibleUnitList = [...responsibleUnitList];
    }

    function changeUnitProps(unitIndex, props) {
      responsibleUnitList[unitIndex] = props;
      responsibleUnitList = [...responsibleUnitList];
    }

    async function saveResponsibleUnitForm() {
      const saveResp = await setTestAssignees(testID, responsibleUnitList);
      if (!saveResp.success) {
        // TODO :: show error
      } else {
        toggleSelectionModal();
      }
    }

    async function handleSubmit(e) {
      showLoading = true;
      var testFormData = Object.fromEntries(new FormData(e.target));
      testFormData.questions = questions || [];
      testFormData.url_id = url_id;
      testFormData.timer = (timer_minutes * 60) + timer_seconds;
      let saveResp = null;
      errorMessage = "";
      testFormData.questions.forEach( (question) => {
        if (!question?.answer || question?.answer === "EMPTY_ANSWER") {
          errorMessage = "Tüm soruların doğru cevapları işaretlenmelidir."
        }
      });

      if (errorMessage) {
        showLoading = false;
        return;
      }

      if (testID > -1) {
        saveResp = await updateTestByID(testID, testFormData);
      } else {
        saveResp = await createTest(testFormData);
      }

      const saveResult = saveResp;
      if (saveResp.status !== 200) {
        errorMessage = "Sınav kaydedilirken hata oluştu";
      }

      showLoading = false;

      if (saveResult?.success) {
        // TODO: Push notification to screen
        onCancelClick();
      }
    }

    function onCancelClick() {
      navigate('/test-management/tests');
    }

    async function onDeleteClick(e) {
        showLoading = true;
        //TODO: Show prompt to the user
        const res = await deleteTestByID(testID);
        if (res.status != 200) {
          //TODO: Show error message on form footer
        }
        const result = res;
        if (!result?.success) {
          //TODO: Show error message on form footer
        }
        showLoading = false;
        navigate('/test-management/tests');
    }

    function removeQuestion(q_index) {
        questions.splice(q_index, 1);
        questions = [...questions];
    }

    function addNewQuestion() {
      const questionTemplate = {
        q_type: "radio",
        q_name: "",
        options: [],
        q_index: questions?.length || 0,
        answer: "EMPTY_ANSWER"
      };
      questions = [...questions, questionTemplate];
    }

    function addOptionToQuestion(question) {
      if (question.options && question.options.length > -1) {
        question.options = [...question.options, ""];
      } else {
        question.options = [""];
      }
      questions = [...questions];
    }

    function changeOptionValue(question, optIndex, optionValue) {
      question.options[optIndex] = optionValue;
    }

    function updateQuestionTitle(question, newTitle) {
      question.q_name = newTitle;
    }

    function clearOptionFormQuestion(question, optIndex) {
      question.options.splice(optIndex, 1);
      questions = [...questions];
    }

    function setOptionAsQuestionAnswer(question, optIndex) {
      const answer = question.options[optIndex];
      if (answer) {
        question.answer = question.options[optIndex];
        questions = [...questions];
      }
    }

    async function toggleSelectionModal() {
      if (!selectionModalOpen) {
        const assigneeResp = await getTestAssignees(testID);
        console.log(assigneeResp);
        if (!assigneeResp.success) {
          //TODO :: show error
        }
        responsibleUnitList = assigneeResp.content || [];
      }
      selectionModalOpen = !selectionModalOpen;
    }

    onMount( async () => {
        showLoading = true;
        if (testID > -1) {
          const response = await getTestByID(testID);
          if (!response.content) {
            //TODO: Show error about couldn't fetch category
          }
          testTitle = response.content.title;
          testDescription = response.content.description;
          questions = response.content.questions || [];
          url_id = response.content.url_id;
          average_point = response.content.average_point;
          if (!isNaN(parseInt(response?.content?.timer))) {
            timer_minutes = parseInt(response.content.timer / 60);
            timer_seconds = response.content.timer % 60;
          }
          predefinedValues = (await systemGetPredefinedValues()).content;
        } else {
          url_id = customAlphabet('0123456789qwertyuopasdfghjklizxcvbnm', 8)();
        }
        showLoading = false;
    });

  </script>

  <div class="container mx-auto mt-4 min-h-screen-w-header">
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
    >


      <LoadingOverlay showOverlay={showLoading}/>
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">
            { testID > -1 ? `"${testTitle}" Sınavını Düzenle` : 'Yeni Sınav Oluştur'}
          </h6>
          {#if testID > -1}
            <Button text="Sınavı Sil" buttonType="delete" onClick={onDeleteClick} />
          {/if}
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10">
        {#if !showLoading}
        <form id="new-test-form" on:submit|preventDefault={handleSubmit}>
          <div class="flex flex-wrap">
            <div class="w-full px-4 mb-4">
              <TextInput
                fieldName="title"
                labelText="Sınav Adı"
                required
                value={testTitle}
              />
            </div>
            <p class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2 px-4">Cevaplama yapılması gereken süre</p>
            <div class="w-1/2 px-4 mb-4 flex justify-center items-center border-r-2 border-gray-200">
              <div class="flex-1">
                <TextInput
                  onChange={ (value) => timer_minutes = parseInt(value, 10)}
                  type="number"
                  placeholder="Dakika"
                  value={timer_minutes}
                />
              </div>
              <p class="uppercase text-blueGray-600 text-xs font-bold pl-4">dakika</p>
            </div>
            <div class="w-1/2 px-4 mb-4 flex justify-center items-center">
              <div class="flex-1">
                <TextInput
                  onChange={ (value) => timer_seconds = parseInt(value, 10)}
                  type="number"
                  placeholder="Saniye"
                  value={timer_seconds}
                />
              </div>
              <p class="uppercase text-blueGray-600 text-xs font-bold pl-4">saniye</p>
            </div>
            <div class="w-full px-4 mb-4">
              <TextInput
                labelText="Baraj Puanı"
                required
                fieldName="average_point"
                type="number"
                value={average_point}
              />
            </div>
            <div class="w-full px-4 mb-4">
                <Textarea
                    fieldName="description"
                    labelText="Sınav Açıklaması"
                    value={testDescription}
                    required
                />
            </div>
          </div>
        </form>
        <div class="py-8">
          <Button witdhClass="w-full" text="Sorumlu birimleri düzenle" buttonType="edit" onClick={toggleSelectionModal} />
        </div>
        <CardDetailLine title="Sınav Soruları"/>
        {#each questions as question, index }
          <div class="flex flex-wrap border-dashed border-b-2 border-l-2 border-r-2 border-gray-300 p-6" class:border-t-2={index === 0}>
            <div class="w-full flex flex-wrap pb-4">
              <p class="lg:w-9/12 block uppercase text-blueGray-600 text-sm font-bold mb-1">{index + 1}. Soru Bilgileri</p>
              <div class="lg:w-3/12 flex justify-end items-center">
                <InlineButton text="Soruyu Sil" buttonType="delete" onClick={() => removeQuestion(index)} />
              </div>
            </div>
            <div class="w-full justify-center items-center">
              <TextInput
                required
                placeholder="Soru Başlığı"
                onChange={(value) => updateQuestionTitle(question, value)}
                value={question?.q_name}
              />
              <ul class="pt-4">
                <InlineButton text="Yeni Cevap Ekle" buttonType="new-option" onClick={() => addOptionToQuestion(question)} />
                {#each question?.options as option, optIndex}
                  <li class="w-full p-4 flex flex-wrap items-center">
                    <i class="fas cursor-pointer fa-trash text-red-500 mr-2" on:click={() => clearOptionFormQuestion(question, optIndex)}></i>
                    <i class={"fas cursor-pointer fa-check mr-4 " + (question.answer === option ? 'text-green-500' : 'text-blueGray-500')} on:click={() => setOptionAsQuestionAnswer(question, optIndex)}></i>
                    <div class="flex-grow">
                      <TextInput
                        required
                        placeholder={`${optIndex+1}. Cevap Değeri`}
                        onChange={(value) => changeOptionValue(question, optIndex, value)}
                        value={option}
                      />
                    </div>
                  </li>
                {:else}
                  <li></li>
                {/each}
              </ul>
            </div>
          </div>
        {:else}
          <div class="flex flex-wrap border-dashed border-gray-300 p-6 justify-center items-center">
            <h3 class="text-gray-500 font-bold">Sınava soru eklenmemiş</h3>
          </div>
        {/each}
          <div class="pt-4">
          <Button witdhClass="w-full" text="Yeni Soru Oluştur" onClick={addNewQuestion} />
          </div>
        {/if}
      </div>
      {#if errorMessage}
        <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 text-red-500 font-semibold">
          {errorMessage}
        </div>
      {/if}
      <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
        <div class="flex-none">
          <Button text={testID > -1 ? 'Kaydet' : 'Oluştur'} submitsForm="new-test-form" buttonType={testID > -1 ? 'edit' : 'save'} />
          <Button text="Vazgeç" buttonType="cancel" onClick={onCancelClick} />
        </div>
      </div>
    </div>
  </div>
<Modal visible={selectionModalOpen} onClose={toggleSelectionModal} noSpacing={true} title="Sorumlu Birimleri Düzenle" fillWidth={true}>
  {#each responsibleUnitList as unit, index }
    <div class="flex flex-wrap border-dashed border-r-2 border-l-2 border-b-2 border-gray-300 p-6" class:border-t-2={index === 0}>
      <div class="lg:w-1/12 flex justify-center items-center">
        <InlineButton text="Sil" buttonType="delete" onClick={() => removeResponsibleUnit(index)} />  
      </div>
      <div class="lg:w-11/12">
        <PredefinedValueSelect onChange={(values) => changeUnitProps(index, values)} options={predefinedValues} defaultValues={responsibleUnitList[index]} />
      </div>
    </div>
  {:else}
    <div class="flex flex-wrap border-dashed border-gray-300 p-6 justify-center items-center">
      <h3 class="text-gray-500 font-bold">Herhangi bir sorumlu birim tanımlaması yapılmadı</h3>
    </div>
  {/each}
  <div slot="footer" class="p-4 border-t dark:border-gray-600 flex justify-between">
    <Button buttonType="info" text="Yeni tanımlama ekle" onClick={() => responsibleUnitList = [...responsibleUnitList, unitListTemplate]} />
      <div>
        <Button buttonType="edit" text="Kaydet" onClick={saveResponsibleUnitForm} />
        <Button buttonType="cancel" text="Vazgeç" onClick={toggleSelectionModal} />
      </div>
  </div>
</Modal>