<script>
import { onMount } from 'svelte';
import { navigate } from 'svelte-routing';

import {
  updateAppUser,
  getAppUser,
  createNewAppUser,
  systemGetCityList,
  getParentDepartments,
  getDepartments,
  getSubDepartments,
  getCompanies,
  getPositions,
  getTitles
} from '../../helpers/apiHelper.svelte';

import TextInput from "../../components/Form/TextInput.svelte";
import DatePicker from "../../components/Form/DatePicker.svelte";
import Select from "../../components/Form/Select.svelte";
import Button from "../../components/Form/Button.svelte";
import PredefinedValueSelect from "../../components/PredefinedValueSelect/index.svelte";
import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import { notification } from '../../notification-handlers/notification.svelte';
    import CheckboxButton from '../../components/Buttons/CheckboxButton.svelte';

export let userID = -1;

let formTitle = userID > -1 ? 'Kullanıcı Bilgilerini Düzenle' : 'Yeni Kullanıcı Oluştur';
let saveButtonText = userID > -1 ? 'Kaydet' : 'Oluştur';
let showLoading = false;
let userDetails = null;
let workValues = {
  cityList: [],
  parentDepartmentList: [],
  departmentList: [],
  subDepartmentList: [],
  companyList: [],
  positionList: [],
  titleList: [],
  companyList: [],
};

async function handleSubmit(e) {
  showLoading = true;

  const userFormData = Object.fromEntries(new FormData(e.target));
  userFormData.user_type = 'USER';
  let saveResp = null;

  if (userID > -1) {
    let updatedUser = {};

    ["fullname", "phone", "email", "user_type"].forEach( (columnName) => {
      if (typeof userFormData[columnName] !== 'undefined') {
        updatedUser[columnName] = userFormData[columnName];
      }
      delete userFormData[columnName];
    });

    ["birth_date", "onboarding_date", "gender", "employee_number"].forEach( (columnName) => {
      if (typeof userFormData[columnName] !== 'undefined') {
        if (!updatedUser["personal_information"]) {
          updatedUser["personal_information"] = {};
        }
        updatedUser["personal_information"][columnName] = userFormData[columnName];
      }
    });

    ["city_id", "town_id", "company_id", "parent_department_id", "department_id", "sub_department_id", "position_id", "title_id"].forEach( (columnName) => {
      if (typeof userFormData[columnName] !== 'undefined') {
        if (!updatedUser["work_information"]) {
          updatedUser["work_information"] = {};
        }
        updatedUser["work_information"][columnName] = userFormData[columnName];
      }
    });

    saveResp = await updateAppUser(userID, updatedUser);
  } else {
    saveResp = await createNewAppUser(userFormData);
  }    
  const saveResult = saveResp;

  showLoading = false;
  if (saveResult?.success) {
    // TODO: Push notification to screen
    window.scrollTo({top: 0, behavior: 'smooth'});
    notification.toast({
      type: 'success',
      message: 'Kullanıcı başarıyla oluşturuldu.'
    })
    navigate('/user-management/users');
    return;
  } else if (saveResult?.message) {
    notification.toast({
      type: 'error',
      message: saveResult?.message 
    });
  }
}

async function initalizeWorkValues() {
  const cityListResp = await systemGetCityList();
  if (!cityListResp?.success) {
      notification.toast({
        type: 'error',
        message: cityListResp?.message
      });
      return;
  }
  workValues.cityList = cityListResp.content || [];

  const parentDepartmentsResp = await getParentDepartments();
  if (!parentDepartmentsResp?.success) {
      notification.toast({
        type: 'error',
        message: parentDepartmentsResp?.message
      });
      return;
  }
  workValues.parentDepartmentList = parentDepartmentsResp.content || [];

  const departmentsResp = await getDepartments();
  if (!departmentsResp?.success) {
      notification.toast({
        type: 'error',
        message: departmentsResp?.message
      });
      return;
  }
  workValues.departmentList = departmentsResp.content || [];

  const subDepartmentsResp = await getSubDepartments();
  if (!subDepartmentsResp?.success) {
      notification.toast({
        type: 'error',
        message: subDepartmentsResp?.message
      });
      return;
  }
  workValues.subDepartmentList = subDepartmentsResp.content || [];
  
  const companiesResp = await getCompanies();
  if (!companiesResp?.success) {
      notification.toast({
        type: 'error',
        message: companiesResp?.message
      });
      return;
  }
  workValues.companyList = companiesResp.content || [];
  
  const positionsResp = await getPositions();
  if (!positionsResp?.success) {
      notification.toast({
        type: 'error',
        message: positionsResp?.message
      });
      return;
  }
  workValues.positionList = positionsResp.content || [];
  
  const titlesResp = await getTitles();
  if (!titlesResp?.success) {
      notification.toast({
        type: 'error',
        message: titlesResp?.message
      });
      return;
  }
  workValues.titleList = titlesResp.content || [];
  workValues = {...workValues};
}


onMount(async () => {
  showLoading = true;
  await initalizeWorkValues();

  if (userID > -1) {
    const appUser = await getAppUser(userID);
    if (!appUser?.success) {
      notification.toast({
        type: 'error',
        message: appUser?.message
      });
    }
    userDetails = appUser?.content?.user;
  }
  showLoading = false;
});

function onCancelClick() {
  window.scrollTo({top: 0, behavior: 'smooth'});
  navigate('/user-management/users');
}
</script>

<div class="container mx-auto mt-4">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
  >
    <LoadingOverlay showOverlay={showLoading}/>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {formTitle}
        </h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form id="new-user-form" on:submit|preventDefault={handleSubmit}>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Uygulamaya Giriş Bilgileri
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4 mb-4">
            <TextInput
              fieldName="phone"
              labelText="Telefon Numarası"
              required
              value={userDetails?.phone}
              type="tel"
            />
          </div>
          <div class="w-full p-4">
            <CheckboxButton text="Sistem yönetimine dahildir"/>
          </div>
          <div class="w-full p-4">
            <CheckboxButton text="Yetkili olduğu birimlerdeki raporları görüntüleyebilir"/>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Kişisel Bilgiler
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full px-4 mb-4">
            <TextInput fieldName="fullname" value={userDetails?.fullname} labelText="Ad Soyad" required />
          </div>
          <div class="w-full px-4 mb-4">
            <TextInput fieldName="email" value={userDetails?.email} labelText="E-mail" type="email" />
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <DatePicker fieldName="birth_date" value={userDetails?.personal_information?.birth_date} labelText="Doğum Tarihi" required/>
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <Select fieldName="gender" labelText="Cinsiyet" value={userDetails?.personal_information?.gender} selectOptions={[
              { text: "Erkek", value: "M"},
              { text: "Kadın", value: "F"}
            ]} required/>
          </div>
          <div class="w-full px-4 mb-4">
            <DatePicker fieldName="onboarding_date" value={userDetails?.personal_information?.onboarding_date} labelText="İşe Giriş Tarihi"/>
          </div>
          <div class="w-full px-4 mb-4">
            <TextInput fieldName="employee_number" value={userDetails?.personal_information?.employee_number} labelText="Sicil Numarası" required />
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Çalışma Bilgileri
        </h6>
        <div class="flex flex-wrap">
          <PredefinedValueSelect onChange={(values) => console.log(values)} initializeAtFirst={true} defaultValues={userDetails?.work_information} />
        </div>
      </form>
    </div>
    <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
      <div class="flex-none">
        <Button text={saveButtonText} submitsForm="new-user-form" buttonType={userID > -1 ? 'edit' : 'save'} />
        <Button text="Vazgeç" buttonType="cancel" onClick={onCancelClick} />
      </div>
    </div>
  </div>
</div>
