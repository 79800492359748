<script>
	import { getContext } from 'svelte';
	import { TABS } from './Tabs.svelte';

	const tab = {};
	const { registerTab, selectTab, selectedTab } = getContext(TABS);

	registerTab(tab);
</script>
<button class:bg-bo-logo-orange="{$selectedTab === tab}" class="flex-1 mb-2 mx-2 outline-none p-4 text-white bg-gray-300 font-bold text-lg rounded-lg focus:ring-4 focus:ring-blue-300 focus:outline-none" on:click="{() => selectTab(tab)}">
	<slot></slot>
</button>