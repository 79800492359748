<script>
  import { navigate } from 'svelte-routing';
  import { onMount } from 'svelte';
  import {
    getDepartments,
    deleteDepartmentByID,
    deleteParentDepartmentByID,
    deleteSubDepartmentByID,
    getParentDepartments,
    getSubDepartments
  } from "../../helpers/apiHelper.svelte";
  import { locationStore } from '../../store/store.index';
  import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte'
  import ListViewContainer from '../../components/ListView/ListViewContainer.svelte';
  
  let departments = null;
  let isLoading = true;

  let departmentType = "normal";
  let breadcrumbText = "Departman Yönetimi"
  let createButtonText = "Yeni Departman Oluştur"

  if (window.location.href.includes("/parent-department-management")) {
      breadcrumbText = "Ana Departman Yönetimi"
      createButtonText = "Yeni Ana Departman Oluştur"
      departmentType = "parent";
  } else if (window.location.href.includes("/sub-department-management")) {
      breadcrumbText = "Alt Departman Yönetimi"
      createButtonText = "Yeni Alt Departman Oluştur"
      departmentType = "sub";
  }

  function getDepartmentURLPrefix() {
    switch (departmentType) {
      case "parent":
        return "parent-department-management";
      case "sub":
        return "sub-department-management";
      default:
        return "department-management";
    }
  }

  function departmentDelete() {
    if (departmentType == "parent") {
      return deleteParentDepartmentByID;
    } else if (departmentType == "sub") {
      return deleteSubDepartmentByID;
    }
    return deleteDepartmentByID;
  }

  function departmentsGet() {
    if (departmentType == "parent") {
      return getParentDepartments;
    } else if (departmentType == "sub") {
      return getSubDepartments;
    }
    return getDepartments;
  }

  async function initializeDepartments() {
    isLoading = true;
    const resp = await departmentsGet()();
    isLoading = false;
    departments = resp?.content;
    if (departments && departments.length > 0) {
      departments = [...departments];
    }
  }
  
  function onCreateNew() {
    navigate('/' + getDepartmentURLPrefix() + '/departments/new');
  }

  function viewDepartment(departmentID) {
    navigate('/' + getDepartmentURLPrefix() + '/departments/' + departmentID);
  }

  function editDepartment(departmentID) {
    navigate('/' + getDepartmentURLPrefix() + '/departments/' + departmentID + '/edit');
  }

  async function deleteDepartment(departmentID) {
    await departmentDelete()(departmentID);
    // await deleteDepartmentByID(departmentID);
    await initializeDepartments();
    /* setTimeout(async () => {
      
    }, 0); */
  }

  const rowButtons = [
    {
      name: "info",
      props: {
        leftIcon: "fas fa-info",
        buttonType :"info",
        onClick:(departmentID) => viewDepartment(departmentID)
      }
    },
    {
      name: "edit",
      props: {
        leftIcon: "fas fa-edit",
        buttonType :"edit",
        onClick:(departmentID) => editDepartment(departmentID)
      }
    },
    {
      name: "delete",
      props: {
        leftIcon: "fas fa-trash",
        buttonType :"delete",
        onClick:(departmentID) => deleteDepartment(departmentID)
      }
    },
  ]

  
  onMount(async () => {
    locationStore.set({breadcrumbText: breadcrumbText});
    await initializeDepartments();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4 min-h-screen-w-header">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <LoadingOverlay showOverlay={isLoading}/>
    <ListViewContainer
      items={departments}
      createNewText={createButtonText}
      onCreateNew={onCreateNew}
      nameKey="department_name"
      idKey="id"
      rowButtons={rowButtons}
    />
  </div>
</div>
