<script>
import { onMount } from "svelte";


    export let labelText = "";
    export let fieldName = "";
    export let value = "";
    export let required = false;
    let inputID = Math.random().toString(36).slice(2, 12);
    $: {
        if (value && value.indexOf(' ') > -1) {
            value = value.split(' ')[0];
        }
    }
</script>

{#if labelText}
    <label
        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        for={inputID}
    >
        {labelText}
        {#if required}
            <span class="text-red-500">*</span>
        {/if}
    </label>
{/if}
<input
    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
    name={fieldName}
    id={inputID}
    {required}
    bind:value
    type="date"
/>
