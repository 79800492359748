<script>
    import InlineButton from "../../components/Buttons/InlineButton.svelte";
    import {flip} from 'svelte/animate';

    export let items = [];
    export let rowButtons = [];
    export let createNewText = "";
    export let nameKey = "name";
    export let idKey = "id";
    export let onCreateNew = () => null; 
    export let onSorted = () => null;
    
    const drop = (event, target) => {
        event.dataTransfer.dropEffect = 'move'; 
        const start = parseInt(event.dataTransfer.getData("text/plain"));
        const newItems = [...items];

        if (start < target) {
            newItems.splice(target + 1, 0, newItems[start]);
            newItems.splice(start, 1);
        } else {
            newItems.splice(target, 0, newItems[start]);
            newItems.splice(start + 1, 1);
        }
        items = newItems;
        onSorted(items);
    }

    const dragstart = (event, i) => {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setData('text/plain', i);
    }

    const baseItemClasses = "py-4 border-b border-gray-200 w-full rounded-t-lg select-none tree-item pl-4";
    $: {
        if (!items) {
            items = [];
        }
    }
</script>
<div class="flex flex-wrap mt-4 min-h-screen-w-header">
    <div class="w-full mb-12 xl:mb-0 px-4">
        <ul class="bg-white rounded-lg border border-gray-200 text-gray-900">
            <li
                class="{baseItemClasses} font-bold text-center bg-green-500 hover:bg-green-600 active:bg-green-700 text-white cursor-pointer"
                on:click={onCreateNew}>
                {createNewText}
            </li>
            {#each items as item, index (item.id)}
                <li
                    style={`padding-left: 1rem;cursor: row-resize`}
                    class="{baseItemClasses} pr-4"
                    animate:flip="{{duration: 300}}"
                    draggable={true} 
                    on:dragstart={event => dragstart(event, index)}
                    on:drop|preventDefault={event => drop(event, index)}
                    ondragover="return false">
                        <span class="px-2">
                            {item[nameKey]}
                        </span>
                        <span class="float-right tree-actions">
                            {#each rowButtons as rowButton }
                                <InlineButton {...rowButton.props} onClick={ () => rowButton.props.onClick(item[idKey])}/>
                            {/each}
                        </span>
                </li>
            {/each}
        </ul>
    </div>
</div>