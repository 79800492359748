<script>
    export let text = "";
    export let color = "bg-indigo-500";
    export let activeColor = "active:bg-indigo-600";
    export let hoverColor = "hover:bg-indigo-600";
    export let nextRoute = "";
    export let leftIcon = "";
    export let rightIcon = "";
    export let buttonType = "";
    export let textSize = "text-sm";
    export let onClick = null;
    export let onChange = null;
    export let checked = false;
    export let displayOnCheck = '';
    export let displayOnUncheck = '';


    function handleClick(e) {
        if (typeof onClick === 'function') {
            onClick(e.target.checked);
            return;
        }
    }
    //$: checked_r = checked;
</script>
<span>
    <input type="checkbox" class="w-3 h-3 text-green-600 rounded-md focus:ring-0 checkbox-lg" bind:checked={checked} on:click={handleClick} />
    <span class={`${textSize}`}>{text}</span>
    {checked ? displayOnCheck : displayOnUncheck}
</span>