<script>
    import Approver from "../../components/Approver/Approver.svelte";
    import InlineButton from "../../components/Buttons/InlineButton.svelte";
    export let row = null;
    export let onRowEditClick = null;
    export let onRowDeleteClick = null;
</script>
<div>
    <InlineButton text="Düzenle" buttonType="edit" onClick={() => onRowEditClick(row)} />  
    <Approver
        title="Uyarı !"
        onApprove={() => onRowDeleteClick(row)}
    >
        Kullanıcıyı silmek istediğinize emin misiniz ?
    </Approver>
</div>