<script>
  import ProfileDropdown from "../../views/header-bar/ProfileDropdown.svelte";
  import { locationStore } from '../../store/store.index';
  import { userStore } from "../../store/store.index";

  let breadcrumbText = "";
  locationStore.subscribe( (store) => {
    if (store.breadcrumbText) {
      breadcrumbText = store?.breadcrumbText;
    }
  });

  let userData = null;
  userStore.subscribe(store => {
    userData = store;
  });

</script>

<nav class="w-full z-10 bg-bo-logo-blue md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
  <div class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
    <p class="text-white font-bold uppercase">{breadcrumbText}</p>
    <div class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"></div>
    <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
      <img src="/assets/img/adm_gdz_logo.png" class="max-h-6 mr-4">
      <ProfileDropdown user="{userData}"/>
    </ul>
  </div>
</nav>
