<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  import { getUserBalances } from "../../helpers/apiHelper.svelte";

  import DataTable from "../../components/DataTable/DataTable.svelte";
  import { locationStore } from "../../store/store.index";
  import RowOperations from './RowOperations.svelte';

  let userBalances = [];
  let isLoading = true;
  const tableColumns = [
    { name: "Ad Soyad", keyName: "fullname" },
    { name: "Sicil Numarası", keyName: "employee_number" },
    { name: "Telefon Numarası", keyName: "phone" },
    { name: "DEM Para Bakiyesi", keyName: "dem_balance" },
    { name: "TL Bakiyesi", keyName: "tl_balance" },
    { name: 'İşlemler', keyName: 'actions', type: 'actions' }
  ];

  async function initializeUserBalances() {
    const resp = await getUserBalances();
    userBalances = resp?.content;
  }

  function onRowInfoClick(row) {
    navigate("/dem-management/balances/user/" + row.id);
  }
  
  const rowOperations = {
    component: RowOperations,
    props: { onRowInfoClick }
  }

  onMount(async () => {
    console.log('OK');
    locationStore.set({breadcrumbText: 'DEM Para Yönetimi'});
    await initializeUserBalances();
    isLoading = false;
  });

</script>
<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <DataTable
      showLoading={isLoading}
      tableTitle="KULLANICI BAKİYELERİ"
      columns={tableColumns}
      dataSource={userBalances}
      rowOperations={rowOperations}
      showPagination={true}
    />
  </div>
</div>
